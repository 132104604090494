document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");

import 'htmx.org';
import './helpers.js';
import './header.js';
import './src/accordion.js';
import './src/animation.js';
import './src/autocomplete.js';
import './src/breadcrumb.js';
import './src/card-spots.js';
import './src/dropdown.js';
import './src/kadence.js';
import './src/fancybox.js';
import './src/favourites.js';
import './src/forms-modal.js';
import './src/masthead.js';
import './src/modal.js';
import './src/nice-select.js';
import './src/personalisation.js';
import './src/search-form-submit.js';
import './src/slick-sliders.js';
import './src/sliders.js';
import './src/support.js';
import './src/video.js';
import './src/copy-link.js';

import './src/nav/global-nav.js';
import './src/nav/onpage-nav.js';
import './src/nav/subsite-nav.js';
import './src/nav/mega-menu.js';

import './vendor/slick.min.js';

console.log(`Mimas : ${MIMAS_VERSION}`);